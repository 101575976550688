<template>
  <div>
    <div class="font-weight-medium"
         :class="{'display-1': $vuetify.breakpoint.mdAndUp, 'subheading': $vuetify.breakpoint.smAndDown}"
    />
    <v-layout align-center justify-center>
      <div class="size">
        Pick a Subject to Start
      </div>
    </v-layout>
    <br>
    <!--  <divide-content :my-style="style" /> -->
    <br>
    <v-container grid-list-xl text-xs-center>
      <v-layout
        align-center
        justify-center
        row
      >
        <v-flex v-for="topic in topics"
                :key="topic.key"
                xs12
                sm12
                md12
                lg12
                xl12
        >
          <subtopic-card
            :headline="topic.headline"
            :description="topic.description"
            :img="topic.img"
            :path="topic.path"
          />
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import SubtopicCard from './SubTopics.vue'
export default {
  components: {
    SubtopicCard
              },
    data () {
                return {
                  style: {layout: "margin: 10px"},
                  topics: [
                    {
                      headline: "Physics", description: "Selected Examples",
                      img: "assets/Phys.svg", path: "/physics/sample"
                    },
                    {
                      headline: "Chemistry", description: "Selected Examples",
                      img: "assets/Chem.svg", path: "/chemistry/sample"
                    },
                    {
                      headline: "Mathematics", description: "Selected Examples",
                      img: "assets/Math.svg", path: "/mathematics/sample"
                    },
                        ]
                     }
            },
    created () {
              this.$store.commit('navigation/resetState');
              this.$store.commit('navigation/changeTitle', 'Explore Our Content');
              this.$store.commit('navigation/toggleshowhome', false);
              this.$store.commit('navigation/replaceBrain', true);
            },
    metaInfo: {
                    title: 'Examples',
                    titleTemplate: '%s | Learn interactively',
                    meta: [
                            {vmid: 'description', name: 'description', content: 'Explore our visually interactive content on physics, chemistry and math.'},
                            {vmid: 'keywords', name: 'keywords', content: 'Learn to code, visual interactive programming interface'},
                          ]
                     }
                }
</script>
